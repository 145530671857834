<template>
  <div class="container" fluid="lg">
    <h4 class="font-weight-bold text-center my-4">
      {{ $t('term_and_condition') }}
    </h4>

    <English v-if="$i18n.locale == 'en'" />
    <Thailand v-else-if="$i18n.locale == 'th'" />
    <Myanmar v-else />
  </div>
</template>

<script>
import Myanmar from '@/components/tc/Myanmar.vue'
import English from '@/components/tc/English.vue'
import Thailand from '@/components/tc/Thailand.vue'
export default {
  name: 'TermAndCondition',
  components: { Myanmar, English, Thailand },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
